
.mainNav {
    z-index: 99;
    background-color: #FFF;
    border-bottom: 0.125rem solid gray;
    min-height: 64px;
}

.mainNavLink {
    font-size: 1.2rem;
    color: #000000 !important;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0.06rem;
    white-space: nowrap;
    text-decoration: none !important;
}

.mainNavLink:hover {
    color: #0000FF !important;
}

.defaultCursor {
    cursor: default;
}

#blueLink {
    color: #0000FF !important;
}

#applyNavLink {
    margin-left: 2rem;
	color: #dd0a0a;
	font: "Verdana", sans-serif;
	border: 1px solid #fc0404;
	padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

#logInNavLink {
    color: #000;
}

.logoText {
    margin-left: 0.75rem;
    font-weight: 700;
    font-size: 1.1rem;
    font-family: "Times", sans-serif;
    color: #0000FF;
}

.logoImage {
    width: 2.5rem; 
    height: 2rem; 
}

.gridWrapper {
	display: grid;
	/* position: absolute */
  }

.grid-1-1 {
    grid-column: 1;
    grid-row: 1;
    justify-content: left;
    /* justify-self: center; */
}

.grid-2-1 { 
    grid-column: 2;
    grid-row: 1;
    justify-content: center;
    /* justify-self: center; */
}

.grid-3-1 { 
    grid-column: 3;
    grid-row: 1;
    justify-content: right;
    /* justify-self: center; */
}

.justifyRight { 
    justify-content: right;
    justify-self: right;
}

.aboutDropdownMenu {
    border-radius: 3rem!important;
    margin-left: -1.5rem!important;
}

.coursesDropdownMenu {
    border-radius: 3rem!important;
    margin-left: -1rem!important;
}

.accountDropdownMenu {
    border-radius: 3rem!important;
    margin-left: -2.5rem!important;
}

.aboutDropdownMenuCollapsed {
    border-radius: 3rem!important;
}

.coursesDropdownMenuCollapsed {
    border-radius: 3rem!important;
}

.accountDropdownMenuCollapsed {
    border-radius: 3rem!important;
}



#headerDropdown {
    color: rgba(0,0,0,1);
    /* font: 500 1rem "Montserrat", sans-serif; */
    font-weight: bold;
    /* margin-right: 0.5rem; */
    text-align: center;
}

.coursesDropdown {
    padding-top: 2px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    justify-content: center;
    align-items: flex-start;
}

.accountDropdown {
    padding-top: 2px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    justify-content: center;
    align-items: flex-start;
    margin-left: 14rem;
    margin-right: 1rem;
}

.accountDropdownCollapsed {
    padding-top: 2px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    justify-content: center;
    align-items: flex-start;
    margin-left: 0.75rem;
}

#coursesButton {
    border: none;
}

#coursesButton:focus,
#coursesButton:active {
    border: none;
    outline: none;
    box-shadow: none;
}

.logInCollapsed {
    margin-left: 2.5rem;
}

.logInExpanded {
    margin-left: 13rem;
}

.navbar-nav .dropdown-item {
    font-weight: bold;
    text-align: center;
    border-radius: 3rem;
}

.navbar-nav .dropdown-item:hover {
    color: #0000FF;
    background-color: transparent;
}

.accountImage {
    max-height: 2rem;
    width: auto;
}

.accountImage:hover {
    cursor: pointer;
    filter: invert(8%) sepia(100%) saturate(7359%) hue-rotate(248deg) brightness(104%) contrast(145%)
}

#blueFilter {
    filter: invert(8%) sepia(100%) saturate(7359%) hue-rotate(248deg) brightness(104%) contrast(145%)
}

#marginTop {
    margin-top: 0;
}

.headerNavLink {
    margin-top: 0.05rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}