
.dashboardSpinner {
    margin-top: 5rem;
}

.dashboardParent {
    margin-top: 6rem;
}

.dashboardContainer {
    max-width: none;
}

.dashboardSectionContainer {
    margin-bottom: 2rem;
}

.dashboardSectionTitleContainer {
    text-align: left;
}

.dashboardSectionTitle {
    font: 700 1.5rem "Montserrat", sans-serif;
    margin: 0;
}

.dashboardSectionSubTitle {
    font: 400 1rem "arial", sans-serif;
}

.dashboardSectionContentContainer {
    justify-content: center;
}

.dashboardElementContainer {
    padding: 1rem;
    margin: 1rem;
    border: 0.125rem solid #000;
    border-radius: 3rem;
    background-color: #f7f9fa;
    font-weight: bold;
    width: 19.5rem;
}

.dashboardCourseTitle {
    color: #0000FF;
	font: 500 1.5rem "Montserrat", sans-serif;
    margin-top: 1rem;
    margin-bottom: 1rem;
    min-height: 3.4rem;
}

.dashboardImage {
    max-width: 15rem;
    max-height: 8rem;
    object-fit:contain;
    border-radius: 3rem;
}

.dashboardCancelButton {
    margin-top: 1.5rem;
    border: 0.125rem solid #FF0000;
    background-color: #FF0000;
    color: #fff;
    font: 700 1rem "Arial", sans-serif;
    transition: all 0.2s;
    width:100%;
    border-radius: 3rem;
}

.dashboardCancelButton:hover {
    color: #FF0000;
    background-color: #ffffff;
    border: 0.125rem solid #FF0000;
}

.dashboardCancelButton:focus {
    background-color: #FF0000;
    border: 0.125rem solid #FF0000;
    box-shadow: none;
}

.dashboardCancelButton:focus:hover {
    color: #FF0000;
    background-color: #ffffff;
}

.dashboardCancelButton:active{
    background-color: pink !important;
    border: 0.125rem solid #FF0000 !important;
    box-shadow: none!important;
}

.dashboardCancelButton:visited {
    background-color: #FF0000;
}

#dashboardModal .modal-content {
    border: 0;
    border-image: 0;
}

#dashboardModal.modal-dialog {
    padding: 2rem;
}

.cancelModalTitle {
    color: #0000FF;
	font: 500 1.5rem "Montserrat", sans-serif;
    text-align: center;
}
.cancelModalSubTitle {
    font: 700 1.25rem "Montserrat", sans-serif;
}

.cancelModalSpinnerBody {
    text-align: center;
}

.cancelModalSpinnerBody .spinner-border {
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.cancelModalFooter {
    justify-content: center;
}

.modalCancelButton {
    border: 0.125rem solid #FF0000;
    background-color: #FF0000;
    color: #fff;
    font: 700 1rem "Arial", sans-serif;
    transition: all 0.2s;
    border-radius: 3rem;
}
.modalCancelButton:hover {
    color: #FF0000;
    background-color: #ffffff;
    border: 0.125rem solid #FF0000;
}
.modalCancelButton:focus {
    background-color: pink;
    border: 0.125rem solid #FF0000;
    box-shadow: none;
}
.modalCancelButton:active{
    background-color: pink !important;
    border: 0.125rem solid #FF0000 !important;
    box-shadow: none!important;
}
.modalCancelButton:visited {
    background-color: #FF0000;
}

.modalNoButton {
    border: 0.125rem solid #0000FF;
    background-color: #0000FF;
    color: #fff;
    font: 700 1rem "Arial", sans-serif;
    transition: all 0.2s;
    border-radius: 3rem;
}
.modalNoButton:hover {
    color: #0000FF;
    background-color: #ffffff;
    border: 0.125rem solid #0000FF;
}
.modalNoButton:focus {
    background-color: lightblue;
    border: 0.125rem solid #0000FF;
    box-shadow: none;
}
.modalNoButton:active{
    background-color: lightblue !important;
    border: 0.125rem solid #0000FF !important;
    box-shadow: none!important;
}
.modalNoButton:visited {
    background-color: #0000FF;
}

.completedIcon {
    color: #0000FF;
    font-size: 4rem;
}

.cancelledIcon {
    color: #FF0000;
    font-size: 4rem;
}

.noCourses {
    margin-top: 5rem;
    margin-bottom: 2rem;
    font: 700 2rem "Arial", sans-serif;
}