/*****************************************
Table Of Contents:

01. Colors
02. Font
03. Formatting

******************************************/

.h1New {
    margin-bottom: 0 !important;
    font-size: 1rem;
}

.h2New {
    margin-bottom: 0 !important;
}

/******************************/
/*     01. Colors     */
/******************************/
.redColor {
	color: #FF0000;
}

.blueColor {
    color: #0000FF;
}

/******************************/
/*     02. Font     */
/******************************/

.boldFont {
    font-weight: bold;
}

/******************************/
/*     03. Formatting    */
/******************************/

.textLeft {
    text-align: left;
}

.textRight {
    text-align: right;
}

.textCenter {
    text-align: center !important;
}

.opacity {
    opacity: 0.35;
}

.marginBottom {
    margin-bottom: 12rem !important;
}