
.footerContainer {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 100px;
    border-top: 0.1rem solid rgb(201, 198, 198);
    padding-bottom: 5px;
}

.footerSubContainer {
    text-align: center;
    font-size: 15px;
    width: 100%;
    height: 4rem;
    display: grid;
    grid-template-columns: 3fr 3fr 3fr 1fr;
}

.footerTitle {
    font-weight: 700;
    font-size: 1rem;
    font-family: "Times", sans-serif;
}

.footerLinkCol {
    padding: 0;
    /* margin-bottom: 5px; */
    color: unset;
}

.footerLink {
    color: inherit;
    padding: 5px;
}
.item1 {
    border: none;
    background-color: inherit;
    color: #000;
    padding-left: 10px;
    font-size: 20px;
    display: flex;
    justify-content: left;
    align-items: center;
}
.item1:focus {
    outline: 0;
}
.item2 {
    margin: 1px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item3 {
    color: #00FF;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item3:hover {
    background-color: #000000;
    transition: 0.4s;
}
.item4 {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item4:hover {
    background-color: #4267b2;
    transition: 0.4s;
}
.item5 {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item5:hover {
    background-color: #da0e0e;
    transition: 0.4s;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .item1 {
        font-size: 12px;
        padding-left: 3px;
    }
    .item2 {
        font-size: 10px;
    }
    .item3,
    .item4,
    .item5 {
        font-size: 12px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .item1 {
        font-size: 14px;
        padding-left: 5px;
    }
    .item2 {
        font-size: 12px;
    }
    .item3,
    .item4,
    .item5 {
        font-size: 14px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .item1 {
        font-size: 16px;
        padding-left: 6px;
    }
    .item2 {
        font-size: 14px;
    }
    .item3,
    .item4,
    .item5 {
        font-size: 16px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .item1 {
        font-size: 18px;
        padding-left: 8px;
    }
    .item2 {
        font-size: 14px;
    }
    .item3,
    .item4,
    .item5 {
        font-size: 16px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .item1 {
        font-size: 20px;
        padding-left: 10px;
    }
    .item2 {
        font-size: 16px;
    }
    .item3,
    .item4,
    .item5 {
        font-size: 20px;
    }
}